import React, { isValidElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { HelpText } from '~/components/Text';

const Wrapper = styled.div`
  position: relative;
  margin-top: ${props => (props.noMargin ? '0' : props.addTopMargin ? '40px' : '10px')};
  margin-bottom: ${props => (props.noMargin || props.lowProfile ? '0' : '20px')};
  display: ${props => (props.inline ? 'flex' : 'block')};
`;

const StyledLabelContainer = styled.p`
  color: ${props => (props.disabled ? props.theme.lightMedium : props.theme.darkest)};
  font-size: 12px;
  margin-left: 5px;
`;

const CheckboxHelpText = styled(HelpText)`
  margin-left: 25px;
`;

const CheckboxSubWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledLabelSpan = styled.span`
  align-self: ${props => props.align};
  display: flex;
`;

function RenderLabel({ label, align, inverse, disabled, optional }) {
  if (!label) return null;
  if (isValidElement(label)) return label;
  return (
    <StyledLabelSpan align={align}>
      <StyledLabelContainer
        inverse={inverse}
        disabled={disabled}
      >
        {label}
      </StyledLabelContainer>
      {optional && <StyledLabelContainer disabled>(Optional)</StyledLabelContainer>}
    </StyledLabelSpan>
  );
}

RenderLabel.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.func]),
  align: PropTypes.string,
  inverse: PropTypes.bool,
  disabled: PropTypes.bool,
  optional: PropTypes.bool,
};

const InputWrapper = ({
  label,
  align,
  children,
  addTopMargin = false,
  inline = false,
  noMargin = false,
  inverse = false,
  helpText,
  className,
  error,
  disabled,
  type,
  optional = false,
}) => {
  if (type === 'color') return children;
  if (type === 'checkbox') {
    return (
      <div className={className}>
        <CheckboxSubWrapper>
          {children}
          <RenderLabel
            label={label}
            align={align}
            inverse={inverse}
            disabled={disabled}
            optional={optional}
          />
        </CheckboxSubWrapper>
        {helpText && (
          <CheckboxHelpText
            error={error}
            inverse={inverse}
            disabled={disabled}
          >
            {helpText}
          </CheckboxHelpText>
        )}
      </div>
    );
  }

  const HelpTextRender = () =>
    typeof helpText === 'string' ? (
      <HelpText
        error={error}
        inverse={inverse}
        disabled={disabled}
        noMargin={noMargin}
      >
        {helpText}
      </HelpText>
    ) : (
      helpText
    );

  return (
    <Wrapper
      addTopMargin={addTopMargin}
      inline={inline}
      noMargin={noMargin}
      hasHelpText={!!helpText}
      className={className}
      type={type}
    >
      <RenderLabel
        label={label}
        align={align}
        inverse={inverse}
        disabled={disabled}
        optional={optional}
      />
      {children}
      {helpText && <HelpTextRender />}
    </Wrapper>
  );
};

export const propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  align: PropTypes.string,
  type: PropTypes.string,
  inline: PropTypes.bool,
  noMargin: PropTypes.bool,
  addTopMargin: PropTypes.bool,
  inverse: PropTypes.bool,
  helpText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node,
  labelAs: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  featureName: PropTypes.string,
  optional: PropTypes.bool,
};

InputWrapper.propTypes = propTypes;

export default InputWrapper;
