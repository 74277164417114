import styled, { css } from 'styled-components';

const searchCSS = css`
  border: none;
  &:focus {
    outline: none;
  }
`;
const notSearchCSS = css`
  height: 40px;
  padding: 5px 11px;
  border: 1px solid ${props => props.theme.lightMedium};
  &:focus {
    outline: 2px solid ${props => props.theme.copperLightest};
    border-color: transparent;
  }
`;

const TextInput = styled.input`
  width: 100%;
  ${props => (props.search ? searchCSS : notSearchCSS)}
  background-color: ${props => props.theme.lightest};

  border-radius: 5px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;

  &::placeholder {
    color: ${props => props.theme.lightMedium};
  }

  ${props =>
    props.error &&
    css`
      border-color: ${props => props.theme.danger};
    `}

  &:disabled {
    cursor: not-allowed;
    background-color: ${props => props.theme.lighter};
    border-color: ${props => props.theme.lightMedium};
  }
`;

export default TextInput;
