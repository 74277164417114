import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const importAll = requireContext => requireContext.keys().map(requireContext);
const svgFiles = require.context('!@svgr/webpack!~/assets/icons', false, /\.svg$/);
importAll(svgFiles);

const inlineCSS = css`
  display: inline;
`;
const notInlineCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

const IconContainer = styled.div`
  & svg {
    height: ${props => (props.height ? `${props.height}px` : '100%')};
    width: ${props => (props.width ? `${props.width}px` : '100%')};
    & path {
      fill: ${props => (props.isHovered ? props.hoveredColor : props.color)};
    }
  }
  ${props => (props.inline ? inlineCSS : notInlineCSS)}
  ${props =>
    props.isHoverable &&
    css`
      &:hover {
        & svg {
          & path {
            fill: ${props => props.hoveredColor};
          }
        }
      }
    `}
  ${props =>
    props.marginRight &&
    css`
      margin-right: ${props.marginRight};
    `}
  ${props =>
    props.marginLeft &&
    css`
      margin-left: ${props.marginLeft};
    `}
`;

const Icon = ({ name, ...rest }) => {
  if (!name) return;
  const icon = svgFiles(`./${name}.svg`).default();

  return <IconContainer {...rest}>{icon}</IconContainer>;
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isHovered: PropTypes.bool,
  color: PropTypes.string,
  hoveredColor: PropTypes.string,
  isHoverable: PropTypes.bool,
  inline: PropTypes.bool,
  marginRight: PropTypes.number,
  marginLeft: PropTypes.number,
};

export default Icon;
