import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import { outerRings, innerRings } from './paths';

const narrowFadeIn = keyframes`
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
`;

const harshFadeIn = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

function swapColor(props) {
  return keyframes`
    0% {
      fill: ${props.futureColor};
    }
    80% {
      fill: ${props.currentColor};
    }
    100% {
      fill: ${props.currentColor};
    }
  `;
}

const shiftColorAnimation = css`
  animation:
    ${swapColor} ${props => props.animationDuration}ms ease-in-out infinite,
    ${harshFadeIn} ${props => props.animationDuration}ms ease-in-out infinite;
`;

const simpleAnimation = css`
  animation: ${harshFadeIn} ${props => props.animationDuration}ms ease-in-out infinite;
`;

function getAnimationDelay(index, duration, max) {
  return index * (duration / max) - duration;
}

const Path = styled.path.attrs(props => ({
  style: {
    animationDelay: `${getAnimationDelay(props.index, props.animationDuration, 90)}ms`,
  },
}))`
  /* Looks pretty cool */
  /* animation: ${narrowFadeIn} ${props => (props.index % 10) + 500}ms linear infinite;
  animation-delay: ${props => (props.index % 10) + 50}ms; */

  /* MAGIC */
  /* animation: ${narrowFadeIn} 1800ms ease-in-out infinite;
  animation-delay: ${props => props.index * 20}ms; */

  ${props => (props.shiftColor ? shiftColorAnimation : simpleAnimation)}
`;

const rotate = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(360deg);
}
`;

const rotateReverse = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
`;

const InnerGroup = styled.g`
  animation: ${rotateReverse} 3000ms infinite linear;
  animation-play-state: ${props => (props.playAnimation ? 'playing' : 'paused')};
  transform-origin: 37.9% 50%;
`;

const OuterGroup = styled.g`
  animation: ${rotate} 1500ms infinite linear;
  animation-play-state: ${props => (props.playAnimation ? 'playing' : 'paused')};
  transform-origin: 50% 50%;
`;

const darkColors = {
  primaryColor: '#191919',
  secondaryColor: '#8C8C8C',
  futureColor: '#AB7D63',
};

const lightColors = {
  primaryColor: '#E5E5E5',
  secondaryColor: '#F2F2F2',
  futureColor: '#FAE5DC',
};

function OnomondoLogo({
  primaryColor,
  secondaryColor,
  futureColor,
  duration = 900 * 3,
  size = 355,
  animated = false,
  shiftColor = false,
  playAnimation = false,
  light,
}) {
  const finalPrimaryColor = primaryColor || (light ? lightColors.primaryColor : darkColors.primaryColor);
  const finalSecondaryColor = secondaryColor || (light ? lightColors.secondaryColor : darkColors.secondaryColor);
  const finalFutureColor = futureColor || (light ? lightColors.futureColor : darkColors.futureColor);

  if (!animated) {
    return (
      <svg
        width={size}
        height={size}
        viewBox='0 0 355 355'
      >
        <g>
          {outerRings.reverse().map((d, idx) => (
            <path
              key={idx}
              fill={finalPrimaryColor}
              d={d}
            />
          ))}
          <g>
            {innerRings.reverse().map((d, idx) => (
              <path
                key={idx}
                fill={finalSecondaryColor}
                d={d}
              />
            ))}
          </g>
        </g>
      </svg>
    );
  }

  return (
    <svg
      width={size}
      height={size}
      viewBox='0 0 355 355'
    >
      <OuterGroup playAnimation={playAnimation}>
        {outerRings.reverse().map((d, idx) => (
          <Path
            key={idx}
            index={idx}
            animationDuration={duration}
            fill={finalPrimaryColor}
            shiftColor={shiftColor}
            currentColor={finalPrimaryColor}
            futureColor={finalFutureColor}
            d={d}
          />
        ))}
        <InnerGroup playAnimation={playAnimation}>
          {innerRings.reverse().map((d, idx) => (
            <Path
              key={idx}
              index={idx}
              animationDuration={duration}
              fill={finalSecondaryColor}
              shiftColor={shiftColor}
              currentColor={finalSecondaryColor}
              futureColor={finalFutureColor}
              d={d}
            />
          ))}
        </InnerGroup>
      </OuterGroup>
    </svg>
  );
}

OnomondoLogo.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  futureColor: PropTypes.string,
  duration: PropTypes.number,
  size: PropTypes.number,
  animated: PropTypes.bool,
  shiftColor: PropTypes.bool,
  playAnimation: PropTypes.bool,
  light: PropTypes.bool,
};

export default OnomondoLogo;
